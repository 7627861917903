import React, { useState } from 'react';

import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { ClickableDiv, Flex, FlexRow, Navbar } from '../components';
import BaseLayout from '../components/Layouts/BaseLayout';
import useNavigationScroller from '../hooks/useNavigationScroller';
import SideDiamond from '../components/SideDiamond/SideDiamond';
import { Link } from 'gatsby';
import classnames from 'classnames';
import { toast } from 'react-toastify';

const initialRequest = {
  firstName: '',
  lastName: '',
  email: '',
  company: '',
  message: '',
  checks: {
    auditing: false,
    safetyTrainingAcademy: false,
    safetyAssistanceProgram: false,
    sms: false,
    consulting: false,
    internalAuditing: false,
    trainingDevelopment: false,
    manualDevelopment: false,
    customizedSms: false,
    safetyConsulting: false,
    auditingServices: false,
    dataShare: false,
    analytics: false,
    benchmarks: false,
  },
};
const initialTestimonialRequest = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
};

export const SmsPageInternal = () => {
  const [isSubmittingContactForm, setIsSubmittingContactForm] = useState<boolean>(false);
  const [isSubmittingTestimonialForm, setIsSubmittingTestimonialForm] = useState<boolean>(false);
  const wrapper = React.useRef<HTMLDivElement>(null);
  const [request, setRequest] = useState(initialRequest);
  const [testimonialRequest, setTestimonialRequest] = useState(initialTestimonialRequest);

  useNavigationScroller(wrapper);

  function getCheckClass(key: keyof typeof request.checks) {
    return classnames('mdi', {
      'mdi-checkbox-blank-outline': !request.checks[key],
      'mdi-checkbox-marked': request.checks[key],
    });
  }

  function toggleCheckbox(key: keyof typeof request.checks) {
    setRequest({
      ...request,
      checks: {
        ...request.checks,
        [key]: !request.checks[key],
      },
    });
  }

  function submitContactForm() {
    if(isSubmittingContactForm) {
      return;
    }

    setIsSubmittingContactForm(true);
    fetch('https://func-avss-functions-prod.azurewebsites.net/api/SendEmail', {
      method: 'POST',
      body: JSON.stringify({
        configurationId: 'a1ef0700-6f58-42fe-81b5-921c60ddd9c2',
        subject: 'AvSS Contact',
        body: JSON.stringify({
          type: 'contact',
          ...request,
        }),
        bodyIsHtml: true,
      }),
    })
      .then(() => {
        toast.success('Your message has been sent!');
        setRequest(initialRequest);
      })
      .finally(() => {
        setIsSubmittingContactForm(false);
      });
  }

  function submitTestimonialForm() {
    if(isSubmittingTestimonialForm) {
      return;
    }

    setIsSubmittingTestimonialForm(true);
    fetch('https://func-avss-functions-prod.azurewebsites.net/api/SendEmail', {
      method: 'POST',
      body: JSON.stringify({
        configurationId: '8f356214-3ce3-475a-afbf-ad1ba8be6995',
        subject: 'AvSS Testimonial',
        body: JSON.stringify(testimonialRequest),
        bodyIsHtml: true,
      }),
    })
      .then(() => {
        toast.success('Testimonial submitted successfully, thank you for your feedback!');
        setTestimonialRequest(initialTestimonialRequest);
      })
      .finally(() => {
        setIsSubmittingTestimonialForm(false);
      });
  }

  return (
    <BaseLayout title="Contact">
      <Navbar />

      <Row className="flex-fill">
        <Col md={4}></Col>
        <Col className="d-flex flex-column p-3 flex-fill" md={8}>
          <div className="pt-2 px-2">
            <Link to="/">
              <img className="img-fluid hero-opacity" src={require('../images/logo-horizontal.svg').default} alt="Aviation Safety Solutions" style={{ height: '20vh' }} />
            </Link>
          </div>
        </Col>
      </Row>

      <div className="wrapper" ref={wrapper}>
        <Row>
          <Col className="d-none d-md-block" md={4}>
            <Flex center className="side-affix">
              <SideDiamond label={<span>Contact Us</span>} icon={null} />
            </Flex>
          </Col>
          <Col md={7}>
            <section className="px-2 pt-5 body-bg mb-3">
              <form onSubmit={(e) => {
                e.preventDefault();
                submitContactForm();
              }}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="text-white">First Name:</Label>
                      <Input className="text-dark" type="text" required value={request.firstName} onChange={(e) => setRequest({ ...request, firstName: e.target.value })} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="text-white">Last Name:</Label>
                      <Input className="text-dark" type="text" required value={request.lastName} onChange={(e) => setRequest({ ...request, lastName: e.target.value })} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="text-white">Company:</Label>
                      <Input className="text-dark" type="text" value={request.company} onChange={(e) => setRequest({ ...request, company: e.target.value })} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="text-white">Email:</Label>
                      <Input className="text-dark" type="email" required value={request.email} onChange={(e) => setRequest({ ...request, email: e.target.value })} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="contact-selections">
                  <Col md={4}>
                    <h3 className="text-accent text-uppercase mb-0">Aviation Safety</h3>
                    <FormGroup>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('auditing')}><i className={getCheckClass('auditing')} /> Auditing Services</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('sms')}><i className={getCheckClass('sms')} /> SMS Implementation</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('safetyAssistanceProgram')}><i className={getCheckClass('safetyAssistanceProgram')} /> Safety Assistance Program</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('safetyTrainingAcademy')}><i className={getCheckClass('safetyTrainingAcademy')} /> Safety Training Academy</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('consulting')}><i className={getCheckClass('consulting')} /> Consulting Services</ClickableDiv>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <h3 className="text-accent text-uppercase mb-0">Trans/Tech Safety</h3>
                    <FormGroup>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('safetyConsulting')}><i className={getCheckClass('safetyConsulting')} /> Safety Consulting</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('internalAuditing')}><i className={getCheckClass('internalAuditing')} /> Internal Auditing</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('manualDevelopment')}><i className={getCheckClass('manualDevelopment')} /> Manual Development</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('customizedSms')}><i className={getCheckClass('customizedSms')} /> Customized SMS</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('trainingDevelopment')}><i className={getCheckClass('trainingDevelopment')} /> Training Development</ClickableDiv>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <h3 className="text-accent mb-0 text-skyla hero-opacity">Skyla</h3>
                    <FormGroup>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('dataShare')}><i className={getCheckClass('dataShare')} /> Data Share</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('analytics')}><i className={getCheckClass('analytics')} /> Analytics</ClickableDiv>
                      <ClickableDiv className="hero-opacity text-white" onClick={() => toggleCheckbox('benchmarks')}><i className={getCheckClass('benchmarks')} /> Benchmarks</ClickableDiv>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label className="text-white">Message:</Label>
                      <Input className="text-dark" type="textarea" required value={request.message} onChange={(e) => setRequest({ ...request, message: e.target.value })} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <FlexRow justifyBetween alignCenter>
                      <div><h3><em>Contact us today about any of our services.</em></h3></div>
                      <div>
                        {isSubmittingContactForm ? (
                          <Button type="button" className="bg-secondary text-white hero-opacity" color="accent">Submitting...</Button>
                        ) : (
                          <Button type="submit" className="bg-secondary text-white hero-opacity" color="accent">Submit</Button>
                        )}
                      </div>
                    </FlexRow>
                  </Col>
                </Row>
              </form>

              <FlexRow childSpacingX={1} alignCenter>
                <img style={{ maxWidth: 100 }} className="img-fluid" src={require('../images/contact-icons.svg').default} alt="Contact Icons" />

                <div className="ml-3">
                  <h4 className="text-accent mb-0">info@avsafetysolutions.com</h4>
                  <h4 className="text-accent">1-563-340-9937</h4>
                </div>
              </FlexRow>

              <form className="mt-4" onSubmit={(e) => {
                e.preventDefault();
                submitTestimonialForm();
              }}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="text-white">First Name:</Label>
                      <Input className="text-dark" type="text" required value={testimonialRequest.firstName} onChange={(e) => setTestimonialRequest({ ...testimonialRequest, firstName: e.target.value })} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label className="text-white">Last Name:</Label>
                      <Input className="text-dark" type="text" required value={testimonialRequest.lastName} onChange={(e) => setTestimonialRequest({ ...testimonialRequest, lastName: e.target.value })} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label className="text-white">Email:</Label>
                      <Input className="text-dark" type="email" required value={testimonialRequest.email} onChange={(e) => setTestimonialRequest({ ...testimonialRequest, email: e.target.value })} />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label className="text-white">Testimonial:</Label>
                      <Input className="text-dark" type="textarea" required value={testimonialRequest.message} onChange={(e) => setTestimonialRequest({ ...testimonialRequest, message: e.target.value })} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FlexRow justifyBetween alignCenter>
                      <div className="flex-fill">
                        <h3 className="mb-1"><em>We would love to hear about your experience with our safety specialists.</em></h3>
                      </div>
                      <div>
                        {isSubmittingTestimonialForm ? (
                          <Button type="button" className="bg-secondary text-white hero-opacity" color="accent">Submitting...</Button>
                        ) : (
                          <Button type="submit" className="bg-secondary text-white hero-opacity" color="accent">Submit</Button>
                        )}
                      </div>
                    </FlexRow>
                  </Col>
                </Row>
              </form>
            </section>
          </Col>
        </Row>
      </div>
    </BaseLayout>
  );
};

export default () => <SmsPageInternal />;
